import React from "react";
import * as classNames from "./HomePage.module.css";
import Layout from "../components/Layout";
import Head from "../components/Head";
import { SnippetQueryResult, SiteMetadataQueryResult } from "../graphql";
import MarkdownContent from "../components/MarkdownContent";

const IndexPage: React.FC<{
  query: SiteMetadataQueryResult & SnippetQueryResult;
}> = ({ query }) => {
  const { description, title } = query.site.siteMetadata;
  const avatar = "/images/avatar.png";
  return (
    <Layout monoNav={false}>
      <Head>
        <link
          rel="me"
          href="https://social.kararyli.net/@krc"
          title="ActivityPub"
        />
      </Head>
      <header>
        <h1 className={classNames.title}>{title}</h1>
        <p className={classNames.subtitle}>{description}</p>
      </header>
      <div className={classNames.content}>
        <MarkdownContent
          className={classNames.greeting}
          brief={true}
          html={query.file.childMdx.body}
        ></MarkdownContent>
        <img
          className={classNames.avatar}
          src={avatar}
          alt=""
          style={{
            shapeOutside: `url(${avatar})`,
          }}
          // TODO: image sizes incorrectly on initial page load, but
          // Lighthouse wants it how it is.
          // width="322"
          // height="600"
        />
      </div>
    </Layout>
  );
};

export default IndexPage;
