import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import HomePage from "../components/HomePage";
import { SnippetQueryResult, SiteMetadataQueryResult } from "../graphql";

const HomePageHOC: React.FC<{}> = () => {
  const query = useStaticQuery<
    SiteMetadataQueryResult & SnippetQueryResult
  >(graphql`
    query {
      site {
        siteMetadata {
          description
          title
        }
      }
      file(sourceInstanceName: { eq: "snippets" }, name: { eq: "greeting" }) {
        sourceInstanceName
        id
        name
        childMdx {
          body
        }
      }
    }
  `);
  return <HomePage query={query} />;
};
export default HomePageHOC;
